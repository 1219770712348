import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Img from "gatsby-image"

import "./../../styles/products.css"

//import components
import GetStartedBanner from "./../../components/get-started-footer"
import GlobalCoverageSection from "./../../components/global-coverage-section/global-coverage"
import MostPopularIntegrations from "./../../components/most-popular-integration-section/most-popular-integrations"
import TrustedByCompanies from "./../../components/companies-trusted"
/**
 *
 * @param {*} param0
 * @returns
 */
const HeadingSection = ({ data }) => {
  const [productName, setproductName] = useState("Incident Management")
  const [title, settitle] = useState(
    "Keep your customers trust with transparent incident management"
  )
  const [description, setdescription] = useState(
    "Quickly understand what happened, collaborate with team members, troubleshoot, and fix in minutes."
  )
  const [getStartedButton, setgetStartedButton] = useState({
    name: "Get started for free",
    link: "https://app.odown.io/signup",
  })

  return (
    <div className="u-features incident-management">
      {/* <div className="container">
				<h1 className="u-features__heading-title">Reliable uptime & API performance monitoring </h1>
				<p className="u-features__heading-desc">Easy-to-use and accurate real-time monitoring of your websites and APIs to keep you up to date with performance. In the event of an outage, we notify you immediately via email, Slack, Webhooks, SMS, Telegram, or Discord</p>
				<div className="image-cover" >
					<div className="image-wrapper">
						<Img fadeIn={false} loading="eager" fluid={data.headerImage.childImageSharp.fluid}  alt="Website monitoring"/></div>
					</div>
				</div> 
			<div className="u-features__footer-cover"></div> */}
      <div className="container">
        <div className="hero-col hero--text">
          <div className="hero-text__wrapper">
            <h1 className="u-features__heading-title white-text-color">
              {title}
            </h1>
            <p className="u-features__heading-desc white-text-color">
              {description}
            </p>
            <div className="u-features__heading-btns">
              <OutboundLink
                className="btn btn-primary"
                eventCategory="Launch the Odown App"
                eventAction="Click"
                eventLabel="Goto signup"
                href={getStartedButton.link}
                target="_blanc"
              >
                {getStartedButton.name}
              </OutboundLink>
            </div>
          </div>
        </div>
        <div className="hero-col hero--image">
          <div className="image-cover">
            <div className="image-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.headerImage.childImageSharp.fluid}
                alt={productName}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * ! Done
 * @param {*} param0
 * @returns
 */
const EfficientIncidentManagement = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">
              Incident Management
            </span>
            <h2 className="u-main-feature_tc-title">
              Efficient incident management{" "}
            </h2>
            <p className="u-main-feature_tc-desc">
              Incident Management is much like medical triage - an essential
              part of the infrastructure to manage a temporary problem. Our goal
              is to help your teams get up to speed quickly and solve incidents
              with minimum impact on their systems, people, and reputation.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={
                  data.IncidentManagement_EfficientIncidentManagement
                    .childImageSharp.fluid
                }
                alt="Incident Management - Efficient incident management"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/**
 * ! Done
 * @param {*} param0
 * @returns
 */
const QuickAndEasyIncidentProcess = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">
              Incident processing
            </span>
            <h2 className="u-main-feature_tc-title">
              Quick and easy incident process{" "}
            </h2>
            <p className="u-main-feature_tc-desc">
              Our incident management process helps you manage any incident
              faster with all details and information.
            </p>
            <p className="u-main-feature_tc-desc">
              It takes a few seconds to fill out an incident report, so start by
              choosing your affected monitors and the incident's status; set a
              brief description that explains the problem. That's it. DONE
            </p>
            <p className="u-main-feature_tc-desc small">
              You can also share the incident's details with your customers and
              notify your status page's subscribers about any new related
              updates.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={
                  data.IncidentManagement_QuickEasyIncidentProcess
                    .childImageSharp.fluid
                }
                alt="Incident Management - Quick and easy incident process"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 * ! Done
 * @param {*} param0
 * @returns
 */
const AutomateScheduledMaintenance = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">
              Schedule maintenance
            </span>
            <h2 className="u-main-feature_tc-title">
              Automate Scheduled maintenance{" "}
            </h2>
            <p className="u-main-feature_tc-desc">
              There are essential phases in the life of your product, such as
              availability, purchase, and others. You must inform your customers
              of any repairs made on these phases so that they can prepare
              accordingly.
            </p>
            <p className="u-main-feature_tc-desc">
              By using scheduled maintenance, you keep the trust of your
              customers, and they recognize the professionalism of your service
              through alerts and real-time communication.
            </p>
            <p className="u-main-feature_tc-desc small">
              It can also be easy to make your maintenance fully automated by
              setting up the start date, deadline, customer notifications at
              each stage, or maintenance update.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={
                  data.IncidentManagement_AutomatedScheduledMaintenance
                    .childImageSharp.fluid
                }
                alt="Incident Management - Automate Scheduled maintenance"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 * ! Done
 * @param {*} param0
 * @returns
 */
const UpdateIncidentTimeline = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">real time update</span>
            <h2 className="u-main-feature_tc-title">
              Update incident timeline instantly
            </h2>
            <p className="u-main-feature_tc-desc">
              An incident timeline is a useful and tangible way to record the
              history of an incident, to provide evidence of past actions
            </p>
            <p className="u-main-feature_tc-desc">
              When you update your incident with a new event, you help your
              customers understand what's going on and the problem on your
              service. Because our service is real-time, we will inform your
              customers instantly of any updates on your status page or by
              notifications through different alert channels.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={
                  data.IncidentManagement_UpdateIncidentTimeline.childImageSharp
                    .fluid
                }
                alt="Incident Management - Update incident timeline instansl"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/**
 * ! Done
 * @param {*} param0
 * @returns
 */
const RealtimeSyncStatusPage = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">
              Instant synchronization
            </span>
            <h2 className="u-main-feature_tc-title">
              Realtime sync with the status page
            </h2>
            <p className="u-main-feature_tc-desc">
              This feature allows you to share your incident and scheduled
              maintenance content on your status page in real time.
            </p>
            <p className="u-main-feature_tc-desc">
              And after any incident update, we will notify your status page
              subscribers instantly through your available alert channels like
              Email, Slack, Discord, or RSS, and your customers will always
              trust you and your service.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={
                  data.IncidentManagement_RealtimeSyncStatuspage.childImageSharp
                    .fluid
                }
                alt="Incident Management - Realtime sync with status page"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*} param0
 * @returns
 */
const AlertsAndNotifications = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">Alerting</span>
            <h2 className="u-main-feature_tc-title">
              Easy to notify your team and status pages subscribers
            </h2>
            <p className="u-main-feature_tc-desc">
              When you manage your incident or scheduled maintenance, you must
              let your team and your status page's subscribers know about it.
            </p>
            <p className="u-main-feature_tc-desc">
              With just one click, you can alert your customers with the
              necessary information to keep them up to date with any issues.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={
                  data.IncidentManagement_EasyNotifyTeamStatuspageSubscribers
                    .childImageSharp.fluid
                }
                alt="Incident Management - Easy to notify your team and status pages subscribers"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*} param0
 * @returns
 */
const Incident_Management = ({ data }) => (
  <Layout
    currentPage={`product-incident_management`}
    navParams={{ textColor: "white" }}
  >
    <SEO
      title="Optimize Your Incident Response with Odown's Management Tool"
      description="Streamline incident response with Odown's all-in-one solution. Quickly understand issues, collaborate with your team, and resolve incidents in minutes. IncidentManagement made easy."
      pathname={`/product/incident-management`}
      // meta={[
      // 	{
      // 		name : `twitter:image:src`,
      // 		content :  data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
      // 	},
      // 	{
      // 		name : `og:image`,
      // 		content : data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
      // 	},

      // ]}
      image={{
        src: "/general-image-og--incident-management.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <HeadingSection data={data} />
    <TrustedByCompanies direction="left" />
    <EfficientIncidentManagement data={data} />
    <QuickAndEasyIncidentProcess data={data} />
    <AutomateScheduledMaintenance data={data} />
    <UpdateIncidentTimeline data={data} />
    <RealtimeSyncStatusPage data={data} />
    <AlertsAndNotifications data={data} />
    <MostPopularIntegrations data={data} />
    <GlobalCoverageSection data={data} />
    <GetStartedBanner />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }

    headerImage: file(
      relativePath: { eq: "product__incident-management__header.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    IncidentManagement_QuickEasyIncidentProcess: file(
      relativePath: {
        eq: "product__incident-management__option__quick-easy-incident-process.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    IncidentManagement_AutomatedScheduledMaintenance: file(
      relativePath: {
        eq: "product__incident-management__option__automated-scheduled-maintenance.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    IncidentManagement_UpdateIncidentTimeline: file(
      relativePath: {
        eq: "product__incident-management__option__update-incident-timeline.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    IncidentManagement_RealtimeSyncStatuspage: file(
      relativePath: {
        eq: "product__incident-management__option__realtime-sync-statuspage.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    IncidentManagement_EasyNotifyTeamStatuspageSubscribers: file(
      relativePath: {
        eq: "product__incident-management__option__easy-notify-team-statuspage-subscribers.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    IncidentManagement_EfficientIncidentManagement: file(
      relativePath: {
        eq: "product__incident-management__option__efficient-incident-management.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
export default Incident_Management
